import React from "react"
import Layout from "components/layout"

import { HomeBenefits } from "components/pages/home/benefits"

import imgData from "assets/img/save-ibuku/img-form.jpg"
import SaveIbukuFormBiodata from "../../components/pages/save-ibuku/form/form-biodata"
import SaveIbukuFormParticipant from "../../components/pages/save-ibuku/form/form-participant"

const SaveIbukuForm = () => {
  const pageData = {
    title: "Join The Force Now!",
    text:
      "Yeay! Terima kasih sudah ingin menjadi bagian dari gerakan #Saveibuku bersama banyak anak muda Indonesia lainnya!",
    list: [
      { text: "Isi formulir pendaftaran menjadi Referal" },
      { text: "Dapatkan email konfirmasi beserta link referalmu" },
      { text: "Bagikan link referal tersebut kepada ibu yang ingin kamu ajak" },
      {
        text:
          "Ajak merek untuk membeli paket mamogra menggunakan link dan kode referalmu",
      },
      { text: "Kumpulkan Aman Voucher dan nikmati berbagai reward" },
    ],
    image: {
      sourceUrl: imgData,
    },
  }

  return (
    <Layout>
      <HomeBenefits data={pageData} reverse />
      <SaveIbukuFormBiodata />
      <SaveIbukuFormParticipant />
    </Layout>
  )
}

export default SaveIbukuForm
