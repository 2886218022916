import React from "react"

import { Formik, Form } from "formik"
import * as Yup from "yup"

import { Input, DatePicker, Radio } from "components/anti/forms/forms"
import { Button } from "components/anti/buttons/buttons"

const SaveIbukuFormParticipant = () => {
  const initialValues = {
    name: "",
  }

  const validationSchema = Yup.object({
    name: Yup.string().required("Masukkan Nama Anda"),
  })

  const handleSubmit = data => {
    console.log("data", data)
  }
  return (
    <section className="py-main">
      <div className="container">
        <h6 className="mb-5">Masukan nama-nama yang mau kamu refer:</h6>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleChange, touched, errors }) => (
            <Form>
              <div className="form-row row-5">
                <div className="form-group col-md-6">
                  <Input
                    floatingLabel
                    variant={"underline"}
                    label={"Nama"}
                    as="input"
                    id="name"
                    name="name"
                    onChange={handleChange}
                    touched={touched}
                    errors={errors}
                  />
                </div>
                <div className="form-group col-md-6">
                  <Input
                    floatingLabel
                    variant={"underline"}
                    label={"Nomor Handphone"}
                    as="input"
                    id="phoneNumber"
                    name="phoneNumber"
                    onChange={handleChange}
                    touched={touched}
                    errors={errors}
                  />
                </div>
                <div className="form-group col-md-6">
                  <Input
                    floatingLabel
                    variant={"underline"}
                    label={"Alamat Email"}
                    as="input"
                    type="email"
                    id="email"
                    name="email"
                    onChange={handleChange}
                    touched={touched}
                    errors={errors}
                  />
                </div>
                <div className="col-md-6">
                  <button className="btn btn-add-another">
                    <i className="ail ai-plus-circle"></i>
                    Tambah Lainnya
                  </button>
                </div>
                <div className="form-group col-md-6 pt-5">
                  <Button variant="primary">Submit</Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  )
}

export default SaveIbukuFormParticipant
