import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

import { Button } from "../../anti/buttons/buttons"

import floatGfx from "../../../assets/img/floating-3.svg"

export const HomeBenefits = ({ data, reverse }) => {
  const [trigger, anim] = useScrollAnim()

  return (
    <section className="py-main sc-home-benefits" ref={trigger}>
      <div className="container">
        <div className="row row-5">
          <div className={`col-lg-6 col-text ${reverse && "order-lg-last"}`}>
            <h2 className={`h1 ${anim(1)}`}>{data?.title}</h2>
            <p className={`${anim(2)} pr-md-5 mb-0`}>{data?.text}</p>
            {data.buttons && (
              <Button
                variant="link"
                className={`${anim(3)} mb-3`}
                link={data.buttons.url}
              >
                {data.buttons.text}
              </Button>
            )}
            <ul className="list">
              {data?.list.map((data, i) => (
                <li key={i} className={`${anim(4 + i)} mb-3`}>
                  {data.text}
                </li>
              ))}
            </ul>
          </div>
          <div className={`col-lg-6 col-image ${reverse && "order-lg-first"}`}>
            <img
              src={data?.image?.sourceUrl}
              className={`${anim(5)} img-benefits img-fluid `}
              alt={data?.title}
            />
            <img
              src={floatGfx}
              className={`${anim(6)} gfx-wrapper`}
              alt={data?.title}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
